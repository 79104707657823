<template>
     <div class="padding-20">
        <div class="column">
            <div class="flex-row justify-between  align-items-center">
                <div  class="flex-row align-items-center ">
                  <div class="margin-right-20">价格管理平台账号列表</div>
                  <div>
                    <el-input placeholder="请输入账号、姓名、部门、岗位、权限" class="margin-right-20" suffix-icon="el-icon-search" size="mini" @change="searchuser" v-model="userkeyword" style="width: 280px;"></el-input>
                    <el-button @click="adduser()" size="mini" v-if="isshow">添加用户</el-button>
                </div>
                </div>
               
                <div class="flex-row align-items-center "  v-if="isshow">
                    
                    <div class="sub-title font-size-12 margin-right-10 color-sub" size="mini">为未配置权限的账号批量配置权限</div>
                    <el-button @click="gosetlist" size="mini">批量配置权限</el-button>
                </div>
            </div>
            <div class="margin-top-10">
                <el-table
                :data="userList"
                style="width: 100%">
                <el-table-column
                    prop="loginName"
                    label="账号"
                    width="180">
                </el-table-column>
                <el-table-column
                    prop="userName"
                    label="姓名"
                    width="180">
                </el-table-column>
                <el-table-column
                    prop="orgName"
                    label="部门">
                </el-table-column>
                <el-table-column
                    prop="jobName"
                    label="岗位">
                </el-table-column>
                <el-table-column
                    
                    label="权限配置">
                    <template v-slot="scope">
                           {{scope.row.isPower||scope.row.includeAdmin?scope.row.roles:'未配置'}} 
                    </template>
                </el-table-column>
                <el-table-column
                    label="权限操作" >
                    <template v-slot="scope">
                        <span class="link-text margin-right-10" @click="removepower(scope.row.userId)" v-if="!scope.row.includeAdmin && scope.row.isPower && isshow">移除</span>
                        <span class="link-text margin-right-10" @click="setpowerforuser(scope.row.userId)"  v-if="!scope.row.includeAdmin">{{scope.row.isPower?'调整':'配置'}}权限</span>
                        <span class="link-text" @click="openmove(scope.row)"  v-if="(scope.row.isPower || scope.row.includeAdmin) && isshow">移交权限</span>
                        <!-- <el-button @click="removepower(scope.row.userId)" type="text" v-if="!scope.row.includeAdmin">移除</el-button>
                        <el-button @click="setpowerforuser(scope.row.userId)" type="text"  v-if="!scope.row.includeAdmin">{{scope.row.isPower?'调整':'配置'}}权限</el-button> -->
                        <!-- <el-button @click="openmove(scope.row)" type="text"  v-if="scope.row.isPower">权限移交</el-button> -->
                    </template>
                </el-table-column>
                <el-table-column
                    label="账号操作"  width="120">
                    <template v-slot="scope">
                        <span class="link-text margin-right-10" @click="edituser(scope.row)"  v-if="scope.row.localUserType!='iam' && isshow">修改</span>
                        <span class="link-text margin-right-10"  @click="deleteuser(scope.row.userId)"  v-if="scope.row.localUserType!='iam' && isshow">删除</span>
                    </template>
                </el-table-column>
                </el-table>
            </div>
        </div>
        <!-- 添加用户弹窗 --- @before-close="clearfrom()--->
        <el-dialog
            :title="curUser.userid?'编辑用户':'添加用户'"
            :visible.sync="adduservisiable"
            :modal-append-to-body=false
            :close-on-click-modal=false
           
            :destroy-on-close=true
            width="30%"
           >
            <div>
                <el-form ref="form" :model="curUser" :rules="rules"  label-width="70px">
                    <el-form-item label="账号" prop="loginName">
                        <el-input v-model="curUser.loginName" size="mini"></el-input>
                    </el-form-item>
                    <el-form-item label="姓名" prop="userName"> 
                        <el-input v-model="curUser.userName" size="mini"></el-input>
                    </el-form-item>
                    <el-form-item label="密码" prop="password" v-if="curUser.userid">
                        <el-input v-model="curUser.password" size="mini" type="password"></el-input>
                    </el-form-item>
                    <el-form-item label="确认密码" prop="checkPass"  v-if="curUser.userid">
                        <el-input v-model="curUser.checkPass" size="mini" type="password"></el-input>
                    </el-form-item>
                   
                    </el-form>
            </div>
            
            <div class="margin-top-20 flex-row justify-center">
                <el-button @click="adduservisiable = false" size="mini" style="width: 100px;">取 消</el-button>
                <el-button  @click="saveuserinfo()"  type="primary" size="mini" style="width: 100px;">确 定</el-button>
            </div>
            </el-dialog>
        <!-- 添加用户弹窗结束 -->
        <!-- 权限移交窗口开始 -->
        <el-dialog
            title="移交权限"
            :visible.sync="movepowervisiable"
            :modal-append-to-body=false
            :close-on-click-modal=false
            class="movedialog"
            width="700"
           >
            <div class="border-top margin-top-10">
                <div class="flex-row margin-tb-20">
                    <div class="border-right padding-right-40  margin-right-40">
                    <div class="font-size-12 color-sub margin-bottom-5 line-height-mini">账号</div>
                    <div class="font-size-14 bold"> {{removeuser.loginName}}</div>
                    </div>
                    <div class="border-right padding-right-40 margin-right-40  line-height-mini">
                    <div class="font-size-12 color-sub margin-bottom-5  ">姓名</div>
                    <div class="font-size-14 bold"> {{removeuser.userName}}</div>
                    </div>
                    <div class="border-right padding-right-40  margin-right-40  line-height-mini">
                    <div class="font-size-12 color-sub margin-bottom-5 ">部门</div>
                    <div class="font-size-14 bold"> {{removeuser.orgName}}</div>
                    </div>
                    <div class="">
                    <div class="font-size-12 color-sub margin-bottom-5  line-height-mini">岗位</div>
                    <div class="font-size-14 bold"> {{removeuser.jobName}}</div>
                    </div>
                </div>
                <div class="border-top padding-top-20">
                        <div  class="flex-row align-items-center ">
                            <div class="margin-right-20">请选择您要赋予权限的账号</div>
                            <div>
                                <el-input placeholder="请输入账号、姓名、部门、岗位、权限" class="margin-right-20" suffix-icon="el-icon-search" size="mini" @change="searchuserformove" v-model="userkeywordformove" style="width: 280px;"></el-input>
                                
                            </div>
                        </div>
                        <div class="margin-top-10">
                            <el-table
                            :data="selectlist"
                            style="width: 100%"
                            height="calc(70vh - 250px)"
                            @selection-change="handleSelectionChange"
                            >
                            <el-table-column
                            type="selection"
                            width="55">
                            </el-table-column>
                            <el-table-column
                                prop="loginName"
                                label="账号"
                                width="180">
                            </el-table-column>
                            <el-table-column
                                prop="userName"
                                label="姓名"
                                width="180">
                            </el-table-column>
                            <el-table-column
                                prop="orgName"
                                label="部门">
                            </el-table-column>
                            <el-table-column
                                prop="jobName"
                                label="岗位">
                            </el-table-column>
                            <el-table-column
                                
                                label="权限配置">
                                <template v-slot="scope">
                                    {{scope.row.isPower||scope.row.includeAdmin?scope.row.roles:'未配置'}} 
                                </template>
                            </el-table-column>
                            
                            </el-table>
                        </div>
                </div>
            </div>
            <div class="margin-top-20 flex-row justify-center">
                <el-button @click="movepowervisiable = false" size="mini" style="width: 100px;">取 消</el-button>
                <el-button type="primary" @click="gosavemove"  size="mini" style="width: 100px;">确 定</el-button>
                <div class="color-sub float-right dialogsub">注：移交权限后，原账号权限会被移除</div>
            </div>
            </el-dialog>
        <!-- 权限移交窗口结束 -->
    </div>
 
   
</template>

<script>
import crypto from "../../common/crypto";
export default {
    name:"alluserlist",
    
    data() {
        var validatePass = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('请输入密码'));
        } else {
          if (this.curUser.checkPass !== '') {
            this.$refs.form.validateField('checkPass');
          }
          callback();
        }
      };
      var validatePass2 = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('请再次输入密码'));
        } else if (value !== this.curUser.password) {
          callback(new Error('两次输入密码不一致!'));
        } else {
          callback();
        }
      };
       return{
            userList:[],
            userListall:[],
            userkeyword:'',
            adduservisiable:false,
            curUser:{
                userid:'',
                userName:'',
                loginName:'',
                password:'',
                checkPass:''
            },
            rules: {
            loginName:[{ required: true, message: '登录名不能为空'}],
            userName: [{ required: true, message: '用户名不能为空' }],
            password: [{ validator: validatePass, trigger: 'blur' }],
            checkPass: [
                { validator: validatePass2, trigger: 'blur' }
            ]
           
            },
            movepowervisiable:false,
            removeuser:{},
            selectlist:[],
            selectlistall:[],
            seleceduser:[],
            userkeywordformove:'',
            isshow:false
       } 
    },
    methods:{
        handleSelectionChange(list){
            this.seleceduser=list;
        },
        gosetlist(){
            this.$router.push('/powermanage/editpowerforlist');
        },
        getList(){
            this.userList=[];
            this.userListall=[];
            this.$instance.get('/manage/getAccountList').then(res=>{
                if(res.data.code==0){
                    this.userList=res.data.info;
                    this.userListall=res.data.info;
                }
            })
        },
        setpowerforuser(uid){
            this.$router.push({name:'editpowerforuser',query:{uid:uid}});
        },
        searchuser(val){
            if(val.trim().length>0){
                this.userList=this.userListall.filter(o=>{
                    return (o.userName && o.userName.indexOf(val.trim())>=0 )|| (o.loginName && o.loginName.indexOf(val.trim())>=0 ) || (o.orgName && o.orgName.indexOf(val.trim())>=0 ) || (o.jobName&&o.jobName.indexOf(val.trim())>=0)  || (o.roles && o.roles.indexOf(val.trim())>=0)
                })
            }else{
                this.userList=this.userListall;
            }
        },
        searchuserformove(val){
            if(val.trim().length>0){
                this.selectlist=this.selectlistall.filter(o=>{
                    return (o.userName && o.userName.indexOf(val.trim())>=0 )|| (o.loginName && o.loginName.indexOf(val.trim())>=0 ) || (o.orgName && o.orgName.indexOf(val.trim())>=0 ) || (o.jobName&&o.jobName.indexOf(val.trim())>=0)  || (o.roles && o.roles.indexOf(val.trim())>=0)
                })
            }else{
                this.selectlist=this.selectlistall;
            }
        },
        openmove(row){
            this.removeuser=row;
            this.movepowervisiable=true;
            this.selectlist=[];
            this.selectlistall=[];
            this.userkeywordformove='';
            this.$instance.get('/manage/getAccountList',{params:{excludeUserId:row.userId}}).then(res=>{
                if(res.data.code==0){
                    this.selectlist=res.data.info;
                    this.selectlistall=res.data.info;
                }
            })
        },
        removepower(uid){
  
          this.$confirm('确认移除此用户的权限吗?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
            }).then(() => {
                this.$instance.post('/manage/dropPowerByName?userId='+uid).then(res=>{
                    if(res.data.code==0){
                        this.$message({
                            message: '操作成功',
                            type: 'success'
                        });
                        this.getList();
                    }
                })
               
            })
          },
          saveuserinfo(){
            console.log('form')
            this.$refs['form'].validate((valid) => {
                if (valid) {
                    if(this.curUser.userid){
                        const tempuser={
                            userId:this.curUser.userid,
                            userName:this.curUser.userName,
                            loginName:this.curUser.loginName,
                        }
                        if(this.curUser.password && this.curUser.password.length>0){
                            tempuser.localPwd=crypto.md5(this.curUser.password)
                        }
                        this.$instance.post('/manage/editAccount',tempuser).then(res=>{
                            if(res.data.code==0){
                                this.adduservisiable=false;
                                this.$message({
                                    message: '操作成功',
                                    type: 'success'
                                });
                                this.getList();
                            }
                        })
                         
                    }else{
                        this.$instance.post('/manage/addAccount',this.curUser).then(res=>{
                            if(res.data.code==0){
                                this.adduservisiable=false;
                                this.$message({
                                    message: '操作成功',
                                    type: 'success'
                                });
                               
                                this.getList();
                            }
                        })
               
            
                    }
                }
                   
            });
          },
          clearfrom(){
            // this.$refs['from'].resetFields();
            // done;
          },
          adduser(){
            
            this.curUser={
                userid:'',
                userName:'',
                loginName:'',
                password:'',
                checkPass:''
            }
            this.adduservisiable=true;
          },
          deleteuser(uid){
            this.$confirm('确认删除此用户吗?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
            }).then(() => {
                this.$instance.post('/manage/delAccount',{userId:uid}).then(res=>{
                    if(res.data.code==0){
                        this.$message({
                            message: '操作成功',
                            type: 'success'
                        });
                        this.getList();
                    }
                })
               
            })
          },
          edituser(row){
           this.curUser={
                userid:row.userId,
                userName:row.userName,
                loginName:row.loginName,
           }
           this.adduservisiable=true;
           
          },
          gosavemove(){
              if(this.seleceduser.length>0){
                    this.$instance.post('/manage/powerTransfer',{originId:this.removeuser.userId,destinationIds:this.seleceduser.map(o=>{return o.userId})}).then(res=>{
                        if(res.data.code==0){
                            this.movepowervisiable=false;
                            this.$message({
                                message: '操作成功',
                                type: 'success'
                            });
                            this.getList();
                        }
                    })
              }else{
                        this.$message({
                            message: '请至少选择一个用户',
                            type: 'warning',
                            center:true,
                            offset: Math.ceil(document.documentElement.clientHeight/2.4)
                        });
              }
          },
          getcheck(){
            this.$instance.get('/manage/checkUseIam').then(res=>{
                if(res.data.code==0){
                    this.isshow=!res.data.info;
                }
            })
          }
    },
    mounted(){
        this.getcheck()
        this.getList()
    }
}
</script>
<style lang="scss" >
.movedialog{
    .el-dialog__body{
        padding-top: 0;
    }
    .dialogsub{
        position: absolute;
        margin-top: 5px;
        right: 40px;
    }
}
</style>
